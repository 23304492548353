import request from "@/utils/request";

// 获免密登录用户列表
export function noLoginUserList(data) {
  return request({
      url: "/admin/base/noLoginUserList",
      method: "get",
      params: data
  });
}
// 添加免密登录用户
export function noLoginUserAdd(data) {
  return request({
      url: "/admin/base/noLoginUserAdd",
      method: "post",
      data
  });
}
// 删除免密登录用户
export function noLoginUserDel(data) {
  return request({
      url: "/admin/base/noLoginUserDel",
      method: "delete",
      data
  });
}