<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item class="generate">
          <el-button type="primary" @click="toAddCourse"
            >添加免密用户</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="freeLoginList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          prop="id"
          label="ID"
          width="75"
          align="center"
        ></el-table-column>
        <el-table-column label="用户手机号" prop="phone" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="create_time" align="center"></el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeFreeLoginUser(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

    <!-- 添加免密用户 -->
    <el-dialog title="添加免密用户" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="用户手机号" label-width="100px">
            <el-input v-model="addForm.phone" placeholder="请输入用户手机号"></el-input>
        </el-form-item>
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" @click="addNow" >立即添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
import {
  noLoginUserList,
  noLoginUserAdd,
  noLoginUserDel
} from "@/api/freeLogin.js";
export default {
  data() {
    return {
      queryForm: {
        page: 1,
        limit: 10,
      },
      freeLoginList: [],
      total: 0,
      addDialog: false,
      addForm: {
        phone: null
      },
    };
  },
  created() {
    this.getFreeLoginList();
  },

  methods: {
    // 获取免密登录用户列表
    getFreeLoginList() {
      noLoginUserList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.freeLoginList = res.data.data;
      });
    },

    // 点击添加免密登录用户按钮
    toAddCourse() {
      this.addDialog = true;
    },

    // 添加任务
    addNow() {
      if (this.addForm.phone == null) {
        this.$message.error("请输入用户手机号");
        return;
      }
      noLoginUserAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加免密登录用户成功");
        this.addDialog = false;
        this.getFreeLoginList();
      });
    },
    
    // 删除用户
    async removeFreeLoginUser(id) {
      const result = await this.$confirm("是否要删除该免密登录用户?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      noLoginUserDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除免密登录用户成功");
        this.getFreeLoginList();
      });
    },
    // 弹窗关闭事件
    closed() {
      this.addForm = {
        phone: null
      };
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      this.getFreeLoginList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getFreeLoginList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getFreeLoginList();
    },
  },
};
</script>

<style lang="less" scoped>
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  img {
    width: 100px;
    height: 100px;
  }
}

.tips {
  color: red;
}

::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
